import React from 'react';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import data from '../data/skpr.json';
import fourOhFour from '../data/404.json';

const { seo, header, footer, cta } = data;
const { site, pageTitle, message } = fourOhFour;

const NotFoundPage = () => (
  <div>
    <SEO title={site.title} seo={seo} />
    <Header header={header} />
    <main>
      <Layout>
        <Heading tag="h1" headingClass="heading--special">
          {pageTitle}
        </Heading>
        <p>{message}</p>
      </Layout>
      <Cta cta={cta} />
    </main>
    <Footer footer={footer} />
  </div>
);

export default NotFoundPage;
